import { observer } from 'mobx-react-lite';
import React, { ComponentType } from 'react';
import { pure } from 'recompose';

import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {
  data: any;
}

export const CommunityItem: ComponentType<Props> = pure(
  observer(({ data }) => {
    const store = useRootStore();
    const { language } = store.globalStore;

    return (
      <div className='card clients-say-card h-100'>
        <div className='card-body'>
          <div className='d-flex align-items-center'>
            <div className='avatar'>
              <img src={data.avatar} alt='avatar' className='avatar-thumbnail' />
            </div>
            <div className='ms-3'>
              <div className='name'>{data[`name_${language}`]}</div>
              <div className='job-title'>{data[`job_${language}`]}</div>
            </div>
          </div>

          <div className='divider'></div>
          <div className='desc' style={{ fontSize: 13 }}>
            {data[`desc_${language}`]}
          </div>
        </div>
      </div>
    );
  }),
);
