import { action, makeObservable, observable, runInAction } from 'mobx';

import { API } from '../configs/APIEndPoint';
import JSonService from '../services/JSonService';
import { INews, INewsUp22, INonProfit, IPartner, IPeopleSay, ISponsor } from './../types/IJson';
import RootStore from './RootStore';

class JSonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  //declare variables
  @observable newsContent: string | null = null;

  @observable listNews: Array<INews> | null = null;
  @observable listPeopleSay: Array<IPeopleSay> | null = null;
  @observable listPartner: Array<IPartner> | null = null;
  @observable listNonProfitOrgan: Array<INonProfit> | null = null;
  @observable listSponsor: Array<ISponsor> | null = null;
  @observable listPeopleSayUp22: Array<IPeopleSay> | null = null;
  @observable listNewsUp22: Array<INewsUp22> | null = null;

  //declare action
  @action getListNews = async () => {
    const result = (await JSonService.getInstance().getData(API.JSON.LIST_NEWS)) as Array<INews>;
    if (result) {
      runInAction(() => {
        this.listNews = result;
      });
    }
  };

  @action getNewsContent = async (url: string) => {
    const result = (await JSonService.getInstance().getData(url)) as string;
    if (result) {
      runInAction(() => {
        this.newsContent = result;
      });
    }
  };

  @action getListPartner = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_PARTNER,
    )) as Array<IPartner>;
    if (result) {
      runInAction(() => {
        this.listPartner = result;
      });
    }
  };

  @action getListPeopleSay = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_PEOPLE_SAY,
    )) as Array<IPeopleSay>;
    if (result) {
      runInAction(() => {
        this.listPeopleSay = result;
      });
    }
  };
  @action getListNonProfit = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_NON_PROFIT_ORGAN,
    )) as Array<INonProfit>;
    if (result) {
      runInAction(() => {
        this.listNonProfitOrgan = result;
      });
    }
  };

  @action getListSponsor = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_SPONSORS,
    )) as Array<ISponsor>;
    if (result) {
      runInAction(() => {
        this.listSponsor = result;
      });
    }
  };

  @action getListPeopleSayUp22 = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_PEOPLE_SAY_UP22,
    )) as Array<IPeopleSay>;
    if (result) {
      runInAction(() => {
        this.listPeopleSayUp22 = result;
      });
    }
  };

  @action getListNewsUp22 = async () => {
    const result = (await JSonService.getInstance().getData(
      API.JSON.LIST_NEWS_UP22,
    )) as Array<INewsUp22>;
    if (result) {
      runInAction(() => {
        this.listNewsUp22 = result;
      });
    }
  };
}
export default JSonStore;
