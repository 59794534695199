import './uprace-2022.css';

import React, { useEffect } from 'react';

import { AboutSection } from './AboutSection/AboutSection';
import { CommunitySection } from './CommunitySection/CommunitySection';
import { HeroSection } from './HeroSection/HeroSection';
import { NewsSection } from './NewsSection/NewsSection';
import { NonProfitSection } from './NonProfitSection/NonProfitSection';
import { SponsorsSection } from './SponsorsSection/SponsorsSection';
import { WhyJoinSection } from './WhyJoinSection/WhyJoinSection';

export const UpRace22LandingPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='uprace_2022'>
      <HeroSection />
      <AboutSection />
      <WhyJoinSection />
      <NonProfitSection />
      <SponsorsSection />
      <CommunitySection />
      <NewsSection />
    </div>
  );
};
