import styled from 'styled-components';

import { InputTextBase } from './BaseStyle';

interface AppInputTextProps {
  isError: boolean;
}
export const FormInputText = styled.input.attrs((props: AppInputTextProps) => ({
  className: props.isError,
}))`
  ${InputTextBase}

  &.error {
    border: solid 1px red;
  }
`;

export const FormInputTextMultiline = styled.textarea.attrs((props: AppInputTextProps) => ({
  className: props.isError,
}))`
  ${InputTextBase}

  &.error {
    border: solid 1px red;
  }

  height: 100px;
  padding-left: 10px;
  padding-right: 10px;
  resize: none;
`;
