import './App.css';
//css import
import 'antd/dist/antd.min.css';
import '../src/assets/css/themes/2022/bootstrap.min.css';
import '../src/assets/css/pages/landing.css';
import '../src/assets/css/themes/2022/index.css';
import '../src/assets/vendor/animate/animate.min.css';
import '../src/assets/vendor/swiper/swiper-bundle.min.css';
import './styles/AntDesign.css';
import './styles/GlobalStyle.css';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { LOCAL_KEY } from './configs/AppKey';
import AppRouter from './configs/Router';
import { useRootStore } from './providers/RootStoreProvider';
import GlobalStyle from './styles/GlobalStyle';

const App: React.FC = () => {
  const store = useRootStore();
  const { changeLanguage } = store.globalStore;

  useEffect(() => {
    onInitAppLanguage();
  }, []);

  //init default app language
  const onInitAppLanguage = () => {
    const key = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);
    if (key && key == 'en') changeLanguage(key);
    else changeLanguage('vi');
  };

  return (
    <BrowserRouter>
      <GlobalStyle />
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
