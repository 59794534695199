import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  EventRuleContainer,
  EventRuleWrapper,
  RankTopImage,
  RankTopTableWrapper,
  RewardCell,
  RuleTitle,
  RuleTitleL1,
  TextIndex,
  TextInfo,
  TextWrapper,
} from '../EventRule.styles';
import { Row } from 'antd';

interface Props {}

export const Rule2024VI: ComponentType<Props> = pure(({}) => {
  return (
    <EventRuleWrapper>
      <EventRuleContainer>
        <RuleTitle>UPRACE 2024</RuleTitle>
        <RuleTitle>ĐIỀU LỆ THAM GIA</RuleTitle>

        <RuleTitleL1>I. Định nghĩa:</RuleTitleL1>
        <TextWrapper>
          <TextIndex></TextIndex>
          <TextInfo>
            UpRace 2024 (sau đây gọi tắt là “sự kiện") là một sự kiện chạy bộ cộng đồng MIỄN PHÍ
            dành cho tất cả mọi người trên nền tảng web/mobile UpRace. Người tham gia (sau đây gọi
            là vận động viên, viết tắt “VĐV”) được tự do chạy vào thời gian và địa điểm tùy ý và chủ
            động ghi lại kết quả chạy trên các thiết bị di động (điện thoại di động, đồng hồ thể
            thao…) với mục đích tích luỹ được quãng đường chạy càng nhiều càng tốt.
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>II. Thời gian:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Thời gian mở đăng ký sự kiện:</strong> bắt đầu từ 10h00 ngày 10/09/2024, VĐV
            vẫn có thể tiếp tục đăng ký và gia nhập Đội thi trong quá trình sự kiện diễn ra.
          </TextInfo>

          <TextInfo>
            <strong>2. Thời gian thi đấu:</strong> từ 00 giờ 00 phút ngày 20/09/2024 tới 23 giờ 59
            phút ngày 13/10/2024 (24 ngày).
          </TextInfo>

          <TextInfo>
            <strong>3. Giờ Việt Nam (GMT+7)</strong> được sử dụng làm múi giờ chuẩn cho sự kiện.
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>III. Cách thức đăng ký:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <b>1.</b> VĐV tải ứng dụng UpRace trên điện thoại di động hoặc truy cập website:
            <a href='https://uprace.org'> https://uprace.org </a> và đăng ký tài khoản. Các VĐV đã
            dự thi các năm trước có thể đăng nhập vào tài khoản sẵn có của mình.
          </TextInfo>

          <TextInfo>
            <b>2.</b> Các VĐV sau khi đăng ký tài khoản thành công sẽ tham gia sự kiện bằng cách bấm
            nút “Tham gia” vào sự kiện UpRace 2024 trong mục “Cộng đồng” {'>'} “Sự kiện” trên ứng
            dụng.
          </TextInfo>

          <TextInfo>
            <b>3.</b> VĐV bấm chọn một (01) Đội (Team) để thi đấu. Mỗi tài khoản của VĐV chỉ được
            chọn một Đội. Các VĐV không được phép thay đổi Đội kể từ thời điểm sự kiện bắt đầu (00
            giờ 00 ngày 20/09/2024) cho tới lúc sự kiện kết thúc (23 giờ 59 phút ngày 13/10/2024).
          </TextInfo>
          <TextInfo>
            <b>4.</b> VĐV tham gia vào sự kiện sau thời điểm sự kiện bắt đầu vẫn được thi đấu bình
            thường.
          </TextInfo>
          <TextInfo>
            <b>5.</b> VĐV bấm tham gia gây quỹ cho một (01) Tổ chức xã hội mà mình lựa chọn. Mỗi tài
            khoản của VĐV chỉ được chọn một (01) Tổ chức xã hội để gây quỹ. Các VĐV không được phép
            thay đổi Tổ chức xã hội mình đã chọn trong quá trình sự kiện diễn ra. Có 3 Tổ chức xã
            hội để VĐV lựa chọn bao gồm:
            <p>
              a. Trung tâm Hỗ trợ Phát triển Chăm sóc Bà mẹ và Trẻ sơ sinh Việt Nam (Sơ sinh Việt
              Nam)
            </p>
            <p>b. Quỹ học bổng Vừ A Dính (VAD)</p>
            <p>c. Hội Bảo trợ người khuyết tật và trẻ mồ côi Việt Nam (ASVDO)</p>
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>IV. Cách thức thi đấu giữa các Đội: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1.</strong> Các đội được xếp vào 3 bảng đấu gồm:{' '}
            <p>a. Bảng 1: gồm các Doanh nghiệp (Company)</p>
            <p>b. Bảng 2: gồm các Câu lạc bộ thể thao (Sports Club)</p>
            <p>c. Bảng 3: gồm các Trường học (Schools)</p>
          </TextInfo>
          <TextInfo>
            <strong>2.</strong> Thành tích của mỗi Đội là tổng thành tích hợp lệ của tất cả các VĐV
            tham gia thi đấu cho Đội trong sự kiện. Các Đội trong cùng bảng sẽ thi đấu với nhau.
          </TextInfo>

          <TextInfo>
            <strong>3.</strong> Các Đội được thành lập sau thời điểm sự kiện bắt đầu vẫn được tham
            gia thi đấu bình thường.
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>V. Cách thức thi đấu đối với các VĐV: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Cách thức ghi lại thành tích: </strong>Các VĐV tự ghi lại thành tích mỗi lần
            chạy của mình bằng thiết bị di động (điện thoại, đồng hồ có chức năng GPS…).
          </TextInfo>
          <TextInfo>
            <strong>2. Cuộc chạy hợp lệ:</strong> Được tính khi đạt đủ các điều kiện sau:
            <p>
              a. Được ghi nhận hoàn tất trên tài khoản UpRace của VĐV trong vòng 72 giờ kể từ thời
              điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối cùng của sự kiện.
            </p>
            <p>
              b. Thể hiện bản đồ chạy rõ ràng, không có dấu hiệu sai số lớn do lỗi bắt sóng GPS của
              thiết bị di động. Các cuộc chạy được nhập kết quả thủ công bằng tay (manual input),
              hoặc được share bởi người khác, hoặc ghi nhận cho nhiều tài khoản khác nhau sẽ không
              được công nhận hợp lệ; Trong trường hợp cuộc chạy thực hiện trên máy chạy bộ cần được
              ghi nhận bằng thiết bị đo chuyên dụng như đồng hồ Garmin và cảm biến đeo chân.
            </p>
            <p>
              c. Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều ứng dụng sẽ chỉ
              được ghi nhận là một cuộc chạy và theo thứ tự ưu tiên sau: Thiết bị chạy bộ đeo tay
              chuyên dụng (bao gồm: Apple Watch, Garmin, Suunto, Coros, Fitbit, Polar) {'>'} UpRace.
            </p>
            <p>
              d. Có cự ly chạy (Distance) tối thiểu là 01km. Cự li tối đa không giới hạn. VĐV được
              bấm tạm dừng cuộc chạy trong khi chạy với mục đích nghỉ và phân phối sức;
            </p>
            <p>
              e. Có tốc độ di chuyển trung bình (Average Moving Pace) tính theo số phút hoàn thành
              01 km chạy nhanh tối đa là 3:30 phút/km và chậm tối thiểu là 15:00 phút/km. Có tốc độ
              di chuyển trung bình của tất cả các quãng 01km (Split) nhanh tối đa 3:00 phút/km.
            </p>
            <p>
              f. Các cuộc chạy diễn ra trong những ngày Chủ nhật trong thời gian diễn ra sự kiện
              phải được hoàn tất trong ngày (trước 24h00) và kết quả nhân 2 được tính vào thành tích
              của ngày đó.
            </p>
          </TextInfo>
          <TextInfo>
            <strong>3. Ngày chạy hợp lệ (active days):</strong> Là ngày có ít nhất một cuộc chạy hợp
            lệ theo điều trên.
          </TextInfo>
          <TextInfo>
            <strong>4. Ngày nhân đôi: </strong>Bao gồm những ngày Chủ nhật trong thời gian sự kiện
            diễn ra.
          </TextInfo>

          <TextInfo>
            <strong>5. Hoàn thành cuộc thi: </strong>Một VĐV sẽ được chứng nhận là hoàn thành cuộc
            thi khi đạt đồng thời hai điều kiện sau:
            <p>a. Có số ngày chạy hợp lệ tối thiểu là 12 ngày.</p>
            <p>
              b. Có tổng số quãng đường chạy tối thiểu là 60km (bao gồm thành tích nhân đôi trong
              các ngày Chủ nhật).
            </p>
            <p>
              Lưu ý: Đối với vận động viên không chạy đủ 12 ngày và 60km, thì số km chạy hoàn thành
              vẫn được đóng góp cho đội và được nhà tài trợ quyên góp cho quỹ.
            </p>
          </TextInfo>
          <TextInfo>
            <strong>6. Ngày ghi nhận thành tích: </strong>Thời điểm bắt đầu cuộc chạy thuộc ngày nào
            thì thành tích của cuộc chạy được tính cho ngày đó.
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>VI. Giải thưởng: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Chứng nhận hoàn thành cuộc thi:</strong>
          </TextInfo>
          <TextInfo>
            Tất cả các VĐV đạt đủ điều kiện hoàn thành cuộc thi sẽ nhận được một giấy chứng nhận
            hoàn thành cuộc thi (bản điện tử) theo các mốc số km hoàn thành gồm: 60km, 150km, 300km
            tương ứng với các danh hiệu: Pro Runner, Hero Runner và Angel Runner.
          </TextInfo>
          <TextInfo>
            <strong>2. Giải thưởng hiện vật:</strong>
          </TextInfo>
          <TextInfo>
            <strong>2.1 Giải thưởng thành tích cho Đội:</strong>
          </TextInfo>
          <TextInfo>
            Mười (10) Đội có tổng thành tích tốt nhất trong mỗi Bảng sẽ nhận giải thưởng đồng đội.
            Giải thưởng đồng đội bao gồm: áo UpRace Winning Team 2024, sẽ trao cho:
            <p>a. Vận động viên đủ điều kiện hoàn thành cuộc thi </p>
            <p>b. Và có thành tích nằm trong top của các Đội đoạt giải theo bảng sau</p>
          </TextInfo>
          <RankTopTableWrapper>
            <Row>
              <RewardCell xl={6} md={6} xs={6}></RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>Doanh nghiệp</strong>
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>Câu lạc bộ</strong>
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>Trường học</strong>
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Đội hạng nhất
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 300 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 250 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 VĐV
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Đội hạng nhì
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 250 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 VĐV
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Đội hạng ba
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 100 VĐV
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Đội hạng 4,5
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 100 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 VĐV
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Đội hạng 6,7,8,9,10
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 VĐV
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 VĐV
              </RewardCell>
            </Row>
          </RankTopTableWrapper>

          <p style={{ paddingTop: 20 }} />
          <TextInfo>
            <strong>2.2 Giải thưởng thành tích cá nhân: </strong>
            Áo UpRace trao cho Top 300 VĐV nam/nữ có thành tích thi đấu cao nhất (không bao gồm các
            VĐV đã nhận giải top 10 các bảng trên).
          </TextInfo>
          <TextInfo>
            <strong>3. Giải thưởng khác:</strong> Giải thưởng dành cho đội có thành tích cao nhất
            mỗi bảng (Top 1):
            <p>a. Giấy chứng nhận thành tích đội thắng cuộc (bản điện tử).</p>
            <p>b. Bài vinh danh đội Top 1 mỗi bảng trên fanpage UpRace.</p>
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>VII. Gây quỹ cho cộng đồng: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            Các Doanh nghiệp, Tổ chức và cá nhân có thể tham gia gây quỹ cho cộng đồng thông qua
            UpRace 2024 với nhiều hình thức khác nhau (theo gói tài trợ, theo quy đổi từ thành tích
            thực tế, theo kêu gọi cộng đồng…). Toàn bộ số tiền gây quỹ sẽ được chuyển trực tiếp đến
            các Đối tác xã hội.
          </TextInfo>
          <TextInfo>
            Vui lòng liên hệ với BTC qua email <a href='mailto:info@uprace.org'>info@uprace.org</a>{' '}
            để được tư vấn về gói tài trợ và các hình thức gây quỹ.
          </TextInfo>
        </TextWrapper>

        <RuleTitleL1>VIII. Quyền của Ban tổ chức (BTC) và Hội đồng kỹ thuật (HĐKT): </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Khiếu nại và Báo cáo: </strong>
            Tổ Chức (BTC) và Hội Đồng Kỹ Thuật (HĐKT) sẽ xem xét và phản hồi các khiếu nại hoặc báo
            cáo của vận động viên trong suốt thời gian diễn ra sự kiện UpRace 2024 trên ứng dụng
            UpRace. Quyết định của HĐKT sẽ là quyết định cuối cùng cho mọi khiếu nại phát sinh.
          </TextInfo>
          <TextInfo>
            <strong>2. Xử phạt: </strong>
            BTC có các biện pháp để phòng ngừa và phát hiện gian lận. Đồng thời, BTC kêu gọi các vận
            động viên duy trì tinh thần thi đấu công bằng và tự giác cao nhất. Nếu phát hiện bất kỳ
            hành vi gian lận nào, vận động viên sẽ bị loại khỏi cuộc thi và toàn bộ thành tích đạt
            được sẽ không được công nhận.
          </TextInfo>
          <TextInfo>
            <strong>3. Quyền của BTC: </strong>
            BTC có toàn quyền quyết định về việc một Đội hoặc vận động viên có đủ điều kiện thi đấu
            hay không. BTC cũng có quyền dừng thi đấu và huỷ kết quả của Đội hoặc vận động viên
            trong trường hợp phát hiện hành vi gian lận hoặc vi phạm quy định cuộc thi, cũng như
            quyền giải quyết các khiếu nại.
          </TextInfo>
        </TextWrapper>
        <p style={{ paddingBottom: 30 }} />
      </EventRuleContainer>
    </EventRuleWrapper>
  );
});
