import { observer } from 'mobx-react-lite';
import React, { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { IPeopleSay } from '../../../types/IJson';
import { CommunityItem } from './CommunityItem';

export const CommunitySection: ComponentType = pure(
  observer(() => {
    const store = useRootStore();
    const { t } = useTranslation();
    const { listPeopleSayUp22, getListPeopleSayUp22 } = store.jsonStore;

    useEffect(() => {
      getListPeopleSayUp22().then();
    }, []);

    return (
      <section className='clients-say-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <h1 className='heading'>{t('uprace2022:clientsSay.heading')}</h1>
            </div>
            <div className='col-md-7'>
              <Swiper
                className='clientsSaySwiper'
                observer={true} //fix issue slider not resize when change breakpoints
                slidesPerView={1}
                spaceBetween={32}
                lazy={true}
                loop={true}
                loopedSlides={listPeopleSayUp22?.length}
                loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                  },
                }}
                modules={[Pagination]}>
                {listPeopleSayUp22 &&
                  listPeopleSayUp22.map((item: IPeopleSay) => {
                    return (
                      <SwiperSlide className='h-auto' key={item.id}>
                        <CommunityItem key={item.id} data={item} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    );
  }),
);
