import { Badge } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { pure } from 'recompose';

import { MenuLanguage } from './MenuLanguage';
import { useRootStore } from '../../../providers/RootStoreProvider';

export const HeaderWeb: ComponentType = pure(
    observer(({}) => {
        const { t, i18n } = useTranslation();
        const store = useRootStore();
        const { isClickNews, setIsClickNews } = store.globalStore;

        console.log('render header web');
        return (
            <header className="header navbar sticky-top navbar-expand-md">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img
                            src="https://uprace2.vcdn.vn/web/uprace/logo/brand-blue.svg"
                            alt="UPRACE"
                            className="navbar-brand-item"
                        />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#upraceNavbar"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="offcanvas offcanvas-top" id="upraceNavbar">
                        <div className="offcanvas-header p-4 border-bottom">
                            <img
                                src="https://uprace2.vcdn.vn/web/uprace/logo/brand-blue.svg"
                                alt="UPRACE"
                                className="offcanvas-title navbar-brand-item"
                            />
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav">
                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(false)}>
                                    <Badge count={t('global:new')}>
                                        <NavLink
                                            className={({ isActive }) =>
                                                isActive
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                            to="/uprace24">
                                            UpRace24
                                        </NavLink>
                                    </Badge>
                                </li>

                                {/* <li className='nav-item'>
                  <a className='nav-link' href='https://upraceday.uprace.org/' target='_blank'>
                    UPRACE Day
                  </a>
                </li> */}

                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(true)}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                        to="/event">
                                        {t('global:events')}
                                    </NavLink>
                                </li>

                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(true)}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                        to="/news">
                                        {t('global:news')}
                                    </NavLink>
                                </li>

                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(false)}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                        to="/about">
                                        {t('global:uprace')}
                                    </NavLink>
                                </li>

                                {/* <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        href="https://event.uprace.org/event"
                                        target="_blank">
                                        {t('global:events')}
                                    </a>
                                </li> */}

                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(false)}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                        to="/contact">
                                        {t('global:contact')}
                                    </NavLink>
                                </li>
                                {/* <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(false)}>
                                    <a
                                        className="nav-link"
                                        href="https://faq.uprace.org/"
                                        target="_blank">
                                        {t('global:support')}
                                    </a>
                                </li> */}

                                <li
                                    className="nav-item"
                                    onClick={() => setIsClickNews(false)}>
                                    <a
                                        className="btn btn-primary btn-download-app"
                                        href="#download-app-section">
                                        {t('global:download')}
                                    </a>
                                </li>
                                <MenuLanguage />
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    })
);
