import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { useRootStore } from '../../../providers/RootStoreProvider';

//styles

interface Props {
  data: any;
}

export const SponsorItem: ComponentType<Props> = observer(({ data }) => {
  const store = useRootStore();
  const { language } = store.globalStore;

  const color = '#a6a3a2';
  const widthTooltipCoTitle = useMediaQuery('(min-width: 768px)') ? '470px' : 'auto';
  const widthTooltipOther = useMediaQuery('(min-width: 768px)') ? '300px' : 'auto';

  const renderTitle = () => {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: data[`desc_${language}`],
        }}
      />
    );
  };

  return (
    <div className='col col-md-2' key={data.id}>
      <div
        className='card h-100'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className='card-body'
          style={{
            width: 150,
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={data.ava} className='d-block mx-auto sponsor-m-img' alt='Brand' />
        </div>
      </div>
    </div>
  );
});
