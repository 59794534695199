import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../providers/RootStoreProvider';

//styles

interface Props {
  item: any;
}

export const NonProfitItem: ComponentType<Props> = observer(({ item }) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { language } = store.globalStore;

  return (
    <div className='col' key={item.id} style={{ padding: 20 }}>
      <div className='card h-100 bg-transparent border-0 text-center'>
        <div className='flip-card position-relative'>
          <div className='flip-card-inner'>
            <div className='flip-card-front'>
              <a className='link-logo' href={item.link} target='_blank' rel='noreferrer'>
                <img src={item.logo} alt='Logo' style={{ height: '80%', objectFit: 'contain' }} className='img-fluid' />
              </a>
            </div>
            <div className='flip-card-back'>
              <a href={item.link} target='_blank' rel='noreferrer'>
                <img src={item.cover} alt='Cover' className='img-fluid img-cover' />
              </a>
            </div>
          </div>
          <div className='position-absolute bottom-0 start-0'>
            <a href={item.link} target='_blank' rel='noreferrer'>
              <button className='btn btn-primary btn-more rounded-0 px-3'>
                {/* <span className="text">
                                    {t('uprace2022:nonProfit.viewDetail')}
                                </span> */}
                <span>&#10095;</span>
              </button>
            </a>
          </div>
        </div>
        <div className='card-body'>
          <h5 className='card-title text-center' dangerouslySetInnerHTML={{__html: item[`name_${language}`]}}></h5>
        </div>
      </div>
    </div>
  );
});
