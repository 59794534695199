import _ from 'lodash';
import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import pure from 'recompose/pure';

import { Rule2023EN } from './Contents/Rule2023EN';
import { Rule2023VI } from './Contents/Rule2023VI';
import { Rule2024VI } from './Contents/Rule2024VI';
import { Rule2024EN } from './Contents/Rule2024EN';
//styles

interface Props {}

const queryString = require('query-string');

export const EventRules: ComponentType<Props> = pure(({}) => {
  const paramsQuery = queryString.parse(window.location.search);
    // if (_.isEmpty(paramsQuery) || !paramsQuery.evid)
    //     return <Navigate to="/"></Navigate>;

  const eventId = paramsQuery.evid;
  const lang = paramsQuery.lang;

  const renderContent = () => {
    if (lang === 'vi') return <Rule2023VI />;
    else return <Rule2023EN />;
  };

  const renderContent24 = () => {
    if (lang === 'vi') return <Rule2024VI />;
    else return <Rule2024EN />;
  };

  if (eventId == 8) return renderContent();
  else return renderContent24();
});
