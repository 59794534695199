import { Col } from 'antd';
import styled from 'styled-components';

import { device } from '../../configs/MediaQuery';

export const EventRuleWrapper = styled.div``;

export const EventRuleContainer = styled.div`
  padding-top: 20px;
  @media ${device.mobileS} {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  @media ${device.laptop} {
    width: 1100px;
    margin: 0 auto;
  }
`;

export const RuleTitle = styled.h1`
  text-align: center;
  @media ${device.mobileS} {
    font-weight: 700;
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const RuleTitleL1 = styled.h1`
  @media ${device.mobileS} {
    font-weight: 700;
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TextIndex = styled.p`
  margin: 0;
  margin-right: 10px;
`;

export const TextInfo = styled.p`
  margin: 0;
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  //line-height: 30px;
  p {
    margin: 0;
    padding-left: 30px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
`;

export const RewardCell = styled(Col)`
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RankTopTableWrapper = styled.div`
  margin-top: 10px;
`;

export const RankTopImage = styled.img`
  width: 100%;
`;
