import styled from 'styled-components';

import { device } from '../../../configs/MediaQuery';

export const HeroSection = styled.section``;

export const BannerWrapper = styled.div`
  margin: 0 auto;

  @media ${device.mobileS} {
    max-width: 342px;
    height: 114px;
  }

  @media ${device.laptop} {
    max-width: 1163px;
    height: 388px;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  @media ${device.mobileS} {
    max-width: 100%;
  }

  @media ${device.laptop} {
    max-width: 1163px;
    max-height: 388px;
  }
`;
