import { observer } from 'mobx-react-lite';
import React, { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IEvent, IGetEventDetailRequest, SPONSOR_EVENT, SPONSOR_TYPE } from '../../../types/IEvent';
import { ISponsor } from '../../../types/IJson';
//views
import { SponsorItem } from './SponsorItem';
import { APIResponse } from '../../../models/APIResponse';
import { API_STATUS } from '../../../configs/APIStatus';
import { useNavigate } from 'react-router-dom';

export const SponsorsSection: ComponentType = pure(
  observer(() => {
    const store = useRootStore();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentEvent, getEventDetail } = store.eventInfoStore;

    useEffect(() => {
      if (!currentEvent) onGetEventDetail();
    }, []);

    const onGetEventDetail = async () => {
      const params: IGetEventDetailRequest = {
        data: {
          evid: process.env.REACT_APP_UPRACE_DAY_ID!,
        },
      };
      const result: APIResponse<IEvent> = await getEventDetail(params);
      //check if events not exist then navigate to not found page
      //if (result.responseCode() === API_STATUS.EVENT.EVENT_NOT_EXIST) navigate('/not-found');
    };

    function getListSponsorByType(type: any) {
      return currentEvent?.spons2[type] || [];
    }

    return (
      <section className='sponsor-section'>
        <div className='container'>
          <div className='row sponsor-header justify-content-center mb-4'>
            <div className='col-12 col-md-8 text-center'>
              <h1 className='heading'>{t('uprace2022:sponsors.heading')}</h1>

              <p className='desc'>{t('uprace2022:sponsors.description')}</p>
            </div>
          </div>

          {getListSponsorByType(SPONSOR_TYPE.CO_TITLE).length > 0 ? (
            <div className='sponsor-item text-center' style={{ display: 'block' }}>
              <h3 className='subtitle'>{t('uprace2022:sponsors.coTitle')}</h3>
              <div className='row justify-content-center align-items-center g-4'>
                {getListSponsorByType(SPONSOR_TYPE.CO_TITLE).map((item: any) => {
                  return <SponsorItem key={item.name} data={item} />;
                })}
              </div>
            </div>
          ) : null}

          {getListSponsorByType(SPONSOR_TYPE.RACE).length > 0 ? (
            <div className='sponsor-item text-center' style={{ display: 'block' }}>
              <h3 className='subtitle'>{t('uprace2022:sponsors.raceSponsors')}</h3>
              <div className='row justify-content-center align-items-center g-4'>
                {getListSponsorByType(SPONSOR_TYPE.RACE).map((item: any) => {
                  return <SponsorItem key={item.name} data={item} />;
                })}
              </div>
            </div>
          ) : null}

          {getListSponsorByType(SPONSOR_TYPE.DONOR).length > 0 ? (
            <div className='sponsor-item text-center' style={{ display: 'block' }}>
              <h3 className='subtitle'>{t('uprace2022:sponsors.donor')}</h3>
              <div className='row justify-content-center align-items-center g-4'>
                {getListSponsorByType(SPONSOR_TYPE.DONOR).map((item: any) => {
                  return <SponsorItem key={item.name} data={item} />;
                })}
              </div>
            </div>
          ) : null}

          {getListSponsorByType(SPONSOR_TYPE.OTHER).length > 0 ? (
            <div className='sponsor-item text-center' style={{ display: 'block' }}>
              <h3 className='subtitle'>{t('uprace2022:sponsors.other')}</h3>
              <div className='row justify-content-center align-items-center g-4'>
                {getListSponsorByType(SPONSOR_TYPE.OTHER).map((item: any) => {
                  return <SponsorItem key={item.name} data={item} />;
                })}
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }),
);
