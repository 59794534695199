import { observer } from 'mobx-react-lite';
import React, { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pure } from 'recompose';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { INonProfit } from '../../../types/IJson';
//views
import { NonProfitItem } from './NonProfitItem';

export const NonProfitSection: ComponentType = pure(
  observer(() => {
    const store = useRootStore();
    const { t } = useTranslation();

    const { listNonProfitOrgan, getListNonProfit } = store.jsonStore;

    useEffect(() => {
      getListNonProfit().then();
    }, []);

    return (
      <section className='non-profit-organ-section'>
        <div className='container'>
          <div className='row non-profit-organ-header justify-content-center'>
            <div className='col-12 col-md-8 text-center'>
              <h1 className='heading'>{t('uprace2022:nonProfit.heading')}</h1>

              <p className='desc'>{t('uprace2022:nonProfit.description')}</p>
            </div>
          </div>
          <div className='non-profit-organ-content'>
            <div className='row row-cols-1 row-cols-md-3 row-cols-lg-3 g-3'>
              {Array.isArray(listNonProfitOrgan) &&
                listNonProfitOrgan &&
                listNonProfitOrgan.map((item: INonProfit) => {
                  return <NonProfitItem key={item.id} item={item} />;
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }),
);
