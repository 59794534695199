import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAboutStats } from '../../../types/IJson';

export const AboutSection: React.FC = () => {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: t('uprace2022:about.content.title1'),
      desc: t('uprace2022:about.content.text1'),
      active: true,
    },
    {
      id: 2,
      title: t('uprace2022:about.content.title2'),
      desc: t('uprace2022:about.content.text2'),
      active: true,
    },
    {
      id: 3,
      title: t('uprace2022:about.content.title3'),
      desc: t('uprace2022:about.content.text3'),
      active: true,
    },
    {
      id: 4,
      title: t('uprace2022:about.content.title4'),
      desc: t('uprace2022:about.content.text4'),
      active: true,
    },
  ];

  return (
    <>
      <section className='about-section'>
        <div className='container'>
          <div className='row about-header justify-content-center'>
            <div className='col-12 col-md-8 text-center'>
              <h1 className='heading'>{t('uprace2022:about.heading')}</h1>

              <p className='desc'>{t('uprace2022:about.description')}</p>
            </div>
          </div>
          <div className='about-content text-center'>
            <div className='row g-3 row-cols-2 row-cols-lg-4 about-content-item'>
              {data.map((item: IAboutStats) => (
                <div className='col' key={item.id}>
                  <h2 className='title'>{item.title}</h2>
                  <p className='text'>{item.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
