import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IWhyJoinStats } from '../../../types/IJson';

export const WhyJoinSection: React.FC = () => {
  const { t } = useTranslation();
  const data: IWhyJoinStats[] = [
    {
      id: 1,
      title: t('uprace2022:whyJoin.title1'),
      desc: '',
      cover: 'https://uprace2.vcdn.vn/web/uprace/images/2022/benefit/pic1.jpg',
      active: true,
    },
    {
      id: 2,
      title: t('uprace2022:whyJoin.title2'),
      desc: '',
      cover: 'https://uprace2.vcdn.vn/web/uprace/images/2022/benefit/pic2.jpg',
      active: true,
    },
    {
      id: 3,
      title: t('uprace2022:whyJoin.title3'),
      desc: '',
      cover: 'https://uprace2.vcdn.vn/web/uprace/images/2022/benefit/pic3.jpg',
      active: true,
    },
  ];

  return (
    <>
      <section className='why-join-section mb-5'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 text-center'>
              <h1 className='heading text-center mb-5'>{t('uprace2022:whyJoin.heading')}</h1>
            </div>
          </div>

          <Swiper
            className='whyJoinSwiper'
            lazy={true}
            loop={true}
            autoplay={{
              delay: 3000,
            }}
            modules={[Autoplay]}>
            {data
              .filter((item: IWhyJoinStats) => item.active)
              .map((filteredItem) => (
                <SwiperSlide key={filteredItem.id}>
                  <div className='row g-4'>
                    <div className='col-12 col-md-5'>
                      <div className='card h-100  border-0 overflow-hidden px-md-4'>
                        <span className='bg-gradient-primary position-absolute top-0 start-0 w-100 h-100 opacity-10 d-none d-md-block'></span>
                        <div className='card-body d-flex flex-column align-items-center justify-content-center position-relative zi-2 p-0 pb-2 p-lg-4'>
                          <h1 className='title text-center text-md-start p-lg-4'>
                            {filteredItem.title}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-7'>
                      <div className='card h-100 border-0'>
                        <img
                          src={filteredItem.cover}
                          alt='thumbnail'
                          className='img-fluid card-img rounded thumbnail'
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};
