import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { INewsUp22 } from '../../../types/IJson';
import { NewsItem } from './NewsItem/NewsItem';

//styles

interface Props {}

export const NewsSection: ComponentType<Props> = observer(({}) => {
    const { t } = useTranslation();
    const store = useRootStore();
    const { isClickNews } = store.globalStore;

    const formRef = useRef<HTMLDivElement | null>(null);

    const { listNewsUp22, getListNewsUp22 } = store.jsonStore;

    useEffect(() => {
        getListNewsUp22().then();
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (formRef.current && isClickNews) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
        return () => {
          clearTimeout(timeoutId);
        };
    }, [formRef, isClickNews]);

    return (
        <section ref={formRef} className="news-section">
            <div  className="container">
                <div className="row news-header">
                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <h1 className="heading mb-4">
                                {t('uprace2022:news.heading')}
                            </h1>
                            {listNewsUp22 && (
                                <a
                                    href="#"
                                    className="link ms-auto"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ display: 'none' }}>
                                    <span className="pe-1">
                                        {t('uprace2022:news.viewMore')}
                                    </span>
                                    <span>&#10095;</span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row mb-4 row-cols-1 row-cols-md-3 g-4">
                    {listNewsUp22 &&
                        listNewsUp22
                            .filter((item: INewsUp22) => item.active)
                            .map((filteredItem) => {
                                return (
                                    <NewsItem
                                        key={filteredItem.id}
                                        data={filteredItem}></NewsItem>
                                );
                            })}
                </div>
            </div>
        </section>
    );
});
