import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TypedEffect } from '../../../components/TypedEffect/TypeEffect';
import {
  GLOBAL_MODAL_TYPE,
  ModalProps,
  useGlobalModalContext,
} from '../../../providers/GlobalModalProvider';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { FormInputText, FormInputTextMultiline } from '../../../styles/InputTextStyles';
import { Section } from '../../../styles/LandingPageStyle';
import { ALERT_TYPE } from '../../../types/IGlobal';
import { IResponseBase } from '../../../types/ITypeBase';
import { IFeedbackInfo, IUserFeedbackRequest } from '../../../types/IUser';
import { ContactFormSchema } from '../../../validation/ContactFormSchema';
//styles
import {
  BannerImage,
  BannerWrapper,
  ContactFormTitle,
  ContactFormTitleSub,
  ContactFormWrapper,
  FormActionContainer,
  FormInput,
  FormInputContainer,
  FormInputTitle,
  FormTitleContainer,
  RowContainer,
  SendButton,
  TextInputContainer,
} from './ContactSection.styles';

export const ContactSection: React.FC = () => {
  const store = useRootStore();
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { sendFeedback } = store.userStore;

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm<IFeedbackInfo>({
    resolver: yupResolver(ContactFormSchema),
  });

  const onSubmit = async (data: IFeedbackInfo) => {
    const params: IUserFeedbackRequest = {
      data: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        content: data.message,
      },
    };
    const result: IResponseBase = await sendFeedback(params);
    if (result.isSuccess()) {
      reset();
      showModal(GLOBAL_MODAL_TYPE.ALERT_MODAL, {
        title: t('contact:sendDialog.titleSuccess'),
        content: t('contact:sendDialog.messageSuccess'),
        alertType: ALERT_TYPE.SUCCESS,
        buttonConfirmText: t('global:close'),
      } as ModalProps);
      return;
    } else {
      showModal(GLOBAL_MODAL_TYPE.ALERT_MODAL, {
        title: t('contact:sendDialog.titleFailed'),
        content: t('contact:sendDialog.messageFailed'),
        alertType: ALERT_TYPE.ERROR,
        buttonConfirmText: t('global:close'),
      } as ModalProps);
    }
  };

  //init array string for typed animation
  const stringsTyped = [t('sport:running'), t('sport:swimming'), t('sport:cycling')];

  return (
    <Section>
      <RowContainer>
        <BannerWrapper>
          <BannerImage src='https://uprace2.vcdn.vn/web/uprace/contact-us/hero-banner-1x.png' />
        </BannerWrapper>

        <ContactFormWrapper>
          <FormTitleContainer>
            <ContactFormTitle>{t('contact:contactForm.title1')}</ContactFormTitle>
            <ContactFormTitle>
              <TypedEffect strings={stringsTyped} />
            </ContactFormTitle>
          </FormTitleContainer>

          <ContactFormTitle>{t('contact:contactForm.title2')}</ContactFormTitle>
          <ContactFormTitleSub>{t('contact:contactForm.description')}</ContactFormTitleSub>

          <FormInput onSubmit={handleSubmit(onSubmit)}>
            <FormInputTitle>{t('contact:contactForm.yourInfo')}</FormInputTitle>

            <FormInputContainer>
              <TextInputContainer xl={12} md={12} sm={24} xs={24}>
                <FormInputText
                  {...register('name')}
                  isError={errors.name ? 'error' : ''}
                  placeholder={`${t('global:fullName')} *`}
                  maxLength={50}
                />
              </TextInputContainer>
              <TextInputContainer xl={12} md={12} sm={24} xs={24}>
                <FormInputText
                  {...register('email')}
                  isError={errors.email ? 'error' : ''}
                  placeholder='Email *'
                  maxLength={250}
                />
              </TextInputContainer>
              <TextInputContainer xl={12} md={12} sm={24} xs={24}>
                <FormInputText
                  {...register('phone')}
                  placeholder={`${t('global:phone')}`}
                  maxLength={20}
                />
              </TextInputContainer>
              <TextInputContainer xl={12} md={12} sm={24} xs={24}>
                <FormInputText
                  {...register('company')}
                  placeholder={`${t('global:company')}`}
                  maxLength={50}
                />
              </TextInputContainer>
            </FormInputContainer>

            <FormInputTitle>{t('global:message')}:</FormInputTitle>

            <FormInputContainer>
              <TextInputContainer span={24}>
                <FormInputTextMultiline
                  {...register('message')}
                  isError={errors.message ? 'error' : ''}
                  placeholder={`${t('global:message')} *`}
                  maxLength={250}
                />
              </TextInputContainer>
            </FormInputContainer>

            <FormActionContainer>
              <SendButton type='submit'>{t('global:send')}</SendButton>
            </FormActionContainer>
          </FormInput>
        </ContactFormWrapper>
      </RowContainer>
    </Section>
  );
};
function showModal(ALERT_MODAL: any, arg1: ModalProps) {
  throw new Error('Function not implemented.');
}
