import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APP_CONFIG } from '../../../configs/GlobalConst';
import useMediaQuery from '../../../hooks/useMediaQuery';
//styles
import { BannerImage, BannerWrapper } from './HeroSection.styles';

export const HeroSection: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <div>
      <section className='hero-section'>
        <div className='container'>
          <div className='row justify-content-center position-relative'>
            <div className='col-12 col-md-10 col-lg-8 text-center'>
              <div className='position-relative'>
                <h1
                  className='heading'
                  dangerouslySetInnerHTML={{
                    __html: t('landing:hero.title'),
                  }}></h1>

                <div className='illustrations'>
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-square.svg'
                    alt='illustration'
                    className='illustration-item square'
                  />
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-triangle.svg'
                    alt='illustration'
                    className='illustration-item triangle'
                  />
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-circle.svg'
                    alt='illustration'
                    className='illustration-item circle'
                  />
                </div>
                <p className='desc'>{t('landing:hero.description')}</p>

                {isDesktop ? (
                  <div className='d-grid gap-2 d-sm-flex justify-content-sm-center'>
                    <a
                      href='https://apps.apple.com/us/app/id1476173971'
                      className='btn-pulse-hover'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/icons/app-store.svg'
                        className='img-fluid app-store'
                        alt='app-store'
                      />
                    </a>

                    <a
                      href='https://play.google.com/store/apps/details?id=vn.vng.uprace'
                      className='d-inline-block ms-md-2 btn-pulse-hover'>
                      <img
                        src='https://uprace2.vcdn.vn/web/uprace/icons/google-play.svg'
                        className='img-fluid google-play'
                        alt='google-play'
                      />
                    </a>
                  </div>
                ) : null}

                <div className='illustrations'>
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-hero-cycle.svg'
                    alt='illustration'
                    className='illustration-item cycle'
                  />
                  <img
                    src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-hero-runner.svg'
                    alt='illustration'
                    className='illustration-item run'
                  />
                </div>
              </div>

              <div className='more'>
                <i className='fi fi-down-sm d-block animate__animated animate__fadeInDown animate__infinite'></i>
                <i className='fi fi-down-lg d-block animate__animated animate__fadeInDown animate__infinite'></i>
              </div>
            </div>
            <div className='col-12 illustrations'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/illustrations/landing-hero-swim.svg'
                alt='illustration'
                className='illustration-item illustration-top'
              />
            </div>
          </div>
        </div>
        <BannerWrapper>
          <Link to={'/uprace24'}>
            <BannerImage src={`${APP_CONFIG.STATIC_URL}/images/banners/homepage-banner.jpg`} />
          </Link>
        </BannerWrapper>
      </section>
    </div>
  );
};
