import { ComponentType } from 'react';
import pure from 'recompose/pure';
//styles
import {
    EventRuleContainer,
    EventRuleWrapper,
    RankTopImage,
    RankTopTableWrapper,
    RewardCell,
    RuleTitle,
    RuleTitleL1,
    TextIndex,
    TextInfo,
    TextWrapper
} from '../EventRule.styles';
import { Row } from 'antd';
import _ from 'lodash';

interface Props {}

export const Rule2023EN: ComponentType<Props> = pure(({}) => {
    return (
        <EventRuleWrapper>
            <EventRuleContainer>
                <RuleTitle>UPRACE 2023</RuleTitle>
                <RuleTitle>PARTICIPATION REGULATION</RuleTitle>

                <RuleTitleL1>I. Definition</RuleTitleL1>
                <TextWrapper>
                    <TextIndex></TextIndex>
                    <TextInfo>
                        UpRace 2023 (hereinafter referred to as "event") is a
                        FREE community running event for everyone on the UpRace
                        web/mobile. Participation (hereinafter referred to as
                        "runner") are free to run at arbitrary time and place
                        and actively record the results of running on mobile
                        devices (mobile phones, sports watches ...) with target
                        accumulates as much running distance as possible.
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>II. Time</RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        <strong>
                            1. Time for event registration opening:{' '}
                        </strong>
                        starting from 00:00 04/09/2023, runners can continue to
                        register and join Teams during the event.
                    </TextInfo>

                    <TextInfo>
                        <strong>2. Competition time: </strong>
                        from 00:00 06/10/2023 to 23:59 29/10/2023 (24 days).
                    </TextInfo>

                    <TextInfo>
                        <strong>3. Vietnamese time (GMT + 7)</strong>is used as
                        the standard time zone for the event.
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>III. How to register:</RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        1. Runners download the UpRace app on mobile phones or
                        visit the website:{' '}
                        <a href="https://uprace.org">https://uprace.org</a> and
                        register an account. The runners, who participated in
                        the 2022 event, can log in to their existing accounts.
                    </TextInfo>

                    <TextInfo>
                        2. After successfully registering an account, runners
                        can follow the step by going through the “Community”
                        section then “Event” section on the app and click the
                        “Join” button to participate in the UpRace 2023 event.
                    </TextInfo>

                    <TextInfo>
                        3. Runners click to join the Team that you want to
                        choose. Each runner's account can only select one Team
                        to compete in the event. The runners are not allowed to
                        change the Team from the time the event starts at 00:00
                        06/10/2023 to 23:59 26/10/2023, when the event ends.
                    </TextInfo>
                    <TextInfo>
                        4. Runners, who join the event after the event has
                        already started, will still be allowed to compete with
                        the others.
                    </TextInfo>
                    <TextInfo>
                        5. The runners then choose to fundraise to a Community
                        Organization that they prefer. Each runner's account can
                        only be chosen to fundraise to one Community
                        Organization. The runners are not allowed to change
                        their chosen Community Organization during the course of
                        the event. There are 3 Community organizations for
                        runners, including:
                        <p style={{ paddingLeft: 30 }}>
                            a. ASVHO (The Association in Support of Vietnamese
                            Handicapped and Orphans)
                        </p>
                        <p style={{ paddingLeft: 30 }}>b. Green Viet</p>
                        <p style={{ paddingLeft: 30 }}>
                            c. Vu A Dinh Scholarship
                        </p>
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>IV. Team's Competition Rules </RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        1. Teams are placed in 3 league tables including:
                        <p style={{ paddingLeft: 30 }}>
                            Table 1: Company Teams
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            Table 2: Sports Club Teams
                        </p>
                        <p style={{ paddingLeft: 30 }}>Table 3: School Teams</p>
                    </TextInfo>
                    <TextInfo>
                        2. Teams in the same Table will compete against each
                        other and rank based on the total running distance of
                        each team member.
                    </TextInfo>

                    <TextInfo>
                        3. Teams, which are established after the event starts,
                        still participate in the competition normally.
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>V. Runners Competition Rules</RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        <strong>1. Record achievements: </strong>The runners
                        record their achievements every runs by mobile device
                        (phone, watch with GPS function ...).
                    </TextInfo>
                    <TextInfo>
                        <strong>2. Valid race:</strong> is counted when
                        committing the following conditions:
                        <p style={{ paddingLeft: 30 }}>
                            - Recorded as completed and displayed on the runner'
                            s UpRace account within 72 hours from the activity's
                            start time and no later than the last day of the
                            event.
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Express the map clearly with no sign of critical
                            errors due to the error of catching GPS signal of
                            mobile devices. The activities which are imported
                            manual results, either shared by others, or recorded
                            for different accounts will not be validated; In
                            case of running on treadmills, the result must be
                            recorded by fitness tracker or smart watch like
                            Garmin; which is able to synchronize with UpRace.
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Activities that are duplicated by recording from
                            multiple devices at a same time will be counted as
                            one activity. The priority order of activity’s
                            source will be: (Garmin, Coros, Suunto, Apple Watch,
                            Fitbit, POLAR…) {'>'} UpRace.
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - There is a minimum distance of 1km. There is an
                            unlimited maximum limit. Runners are allowed to
                            pause the activity while running with the purpose of
                            short break;
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Maximum Average Moving Pace is 3:30 minutes/km and
                            minimum is 15:00 minutes/km, which are calculated by
                            the number of minutes to complete 01 km. Split pace
                            of each 1 km must not be faster than 3:00 minutes/km
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Races that take place on Sundays during the event
                            period must be completed (before 24:00) on that day
                            and double-results will be counted on the
                            achievement of that day.
                        </p>
                    </TextInfo>
                    <TextInfo>
                        <strong>3. Active days:</strong> are the dates of at
                        least one valid run according to the above rule.
                    </TextInfo>
                    <TextInfo>
                        <strong>4. Double-day: </strong>includes Sundays during
                        the event period and special day 10/10/2023.
                    </TextInfo>
                    <TextInfo>
                        <strong>5. Achievement milestones:</strong>
                        <p style={{ paddingLeft: 30 }}>
                            - Milestone 1 (from 1 km to under 500 km): for this
                            milestone, all valid activities specified in Article
                            V.2 will be recorded in achievements.
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Milestone 2 (from 500 km and above): in order to
                            be recognized for achievements from 500 km or more,
                            the valid activities specified in clause V.2 must be
                            recorded by using Specialized Wrist Running Devices
                            including: Garmin , Coros, Suunto, Apple Watch,
                            Fitbit, POLAR
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            *Note: miles include double-results on special days
                            or Sundays
                        </p>
                    </TextInfo>
                    <TextInfo>
                        <strong>6. Complete the contest: </strong>A runner will
                        be certified to complete the contest when both of the
                        following conditions are reached
                        <p style={{ paddingLeft: 30 }}>
                            - Must achieve a minimum number of 12 valid days
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            - Must achieve a minimum distance of 60km (including
                            double achievement on Sundays and on special day
                            10/10/2023).
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            Note: For runners who do not complete the contest
                            (minimum number of 12 valid days and minimum
                            distance of 60km), the completed kilometers are
                            still contributed to the team and fundraised by the
                            sponsor to a social organization.
                        </p>
                    </TextInfo>
                    <TextInfo>
                        <strong>7. Achievement date: </strong>The achievement
                        will be counted on the day when activity starts.
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>VI. Prize: </RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        <strong>1. Individual Prizes:</strong>
                    </TextInfo>
                    <TextInfo>
                        ● All runners who are qualified to finish the
                        competition will receive a certificate of completion of
                        the competition (electronic version) according to the
                        completed km milestones including: 60km, 150km, 300km
                        respectively with the titles: Pro runner, Hero runner
                        and Angel runner.
                    </TextInfo>
                    <TextInfo>
                        <strong>2. In-kind Award:</strong>
                    </TextInfo>
                    <TextInfo>
                        <strong>2.1 Achievement Award for Team: </strong>
                    </TextInfo>
                    <TextInfo>
                        10 teams with the best overall performance in each
                        league table will receive the Team reward. Team reward
                        which includes UpRace Winning Team 2023 t-shirt will be
                        given to
                        <p style={{ paddingLeft: 30 }}>
                            + Runners that complete the contest
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            + And belong to the winning teams, according to the
                            following table:
                        </p>
                    </TextInfo>
                    <RankTopTableWrapper>
                        <RankTopImage src="https://uprace2.vcdn.vn/web/uprace/images/2023/rank_top_en.png"></RankTopImage>
                    </RankTopTableWrapper>
                    {/* <Row>
                        <RewardCell xl={6} md={12} xs={24}></RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            <strong>Company</strong>
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            <strong>Sport club</strong>
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            <strong>School</strong>
                        </RewardCell>
                    </Row>
                    <Row>
                        <RewardCell xl={6} md={12} xs={24}>
                            Rank 1
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 300 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 250 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 200 runners
                        </RewardCell>
                    </Row>
                    <Row>
                        <RewardCell xl={6} md={12} xs={24}>
                            Rank 2
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 250 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 200 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 150 runners
                        </RewardCell>
                    </Row>
                    <Row>
                        <RewardCell xl={6} md={12} xs={24}>
                            Rank 3
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 200 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 150 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 100 runners
                        </RewardCell>
                    </Row>
                    <Row>
                        <RewardCell xl={6} md={12} xs={24}>
                            Rank 4 and 5
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 150 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 100 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 50 runners
                        </RewardCell>
                    </Row>
                    <Row>
                        <RewardCell xl={6} md={12} xs={24}>
                            Rank 6,7,8,9 and 10
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 50 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 50 runners
                        </RewardCell>
                        <RewardCell xl={6} md={12} xs={24}>
                            Top 50 runners
                        </RewardCell>
                    </Row> */}

                    <p style={{ paddingTop: 20 }} />
                    <TextInfo>
                        <strong>2.2 Individual awards:</strong>
                        UpRace T-shirt is awarded to the Top 100 male/female
                        runners with the highest performance (excluding runners
                        who received the top 10 awards in the above table)
                    </TextInfo>
                    <TextInfo>
                        <strong>3. Other prizes: </strong> Prizes for the
                        highest performing team in each group (Top 1):
                        <p style={{ paddingLeft: 30 }}>
                            (1) 100 million VND is fundraised to the UpRace 2023
                            beneficiary that the team designates,
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            (2) Certification of winning team achievement
                            (electronic version),
                        </p>
                        <p style={{ paddingLeft: 30 }}>
                            (3) Posts honoring the Top 1 team in each group on
                            UpRace fanpage.
                        </p>
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>VII. Fundraising for the community: </RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        <strong>1. Origin of funds:</strong>- From sponsors:
                        sponsors will donate at least 1000 VND/1km of valid run
                        from runners
                    </TextInfo>
                    <TextInfo>
                        <strong>2. Use of funds: </strong>- All UpRace’s funds
                        will be transferred to social organizations.
                    </TextInfo>
                </TextWrapper>

                <RuleTitleL1>
                    VIII. Rights of Organizing Committee and Team Administration
                    Board
                </RuleTitleL1>
                <TextWrapper>
                    <TextInfo>
                        <strong>1. Complaint: </strong>
                        Organizing Committee and Team Administration Board only
                        consider complaints related to the results of runners in
                        the top 10 of each Team. Email is the only channel to
                        receive complaints and complaints must be sent by the
                        representative of each team to the Organizer’s email
                        address: support@uprace.vn. The decision of the Team
                        Administration Board is the final decision in all
                        arising complaints.
                    </TextInfo>
                    <TextInfo>
                        <strong>2. Penalty: </strong>
                        The Organizing Committee has many plans to prevent and
                        detect frauds if any, and call runners to show the
                        spirit of fairplay and self-awareness. When any fraud is
                        detected, the runner will be disqualified from the
                        competition and not recognized for all achievements.
                    </TextInfo>
                    <TextInfo>
                        <strong>3. Organizing Committee’s Right: </strong>
                        Administration board, who are the owners and moderator,
                        of each team has full authority to decide whether a team
                        member is eligible to compete or not, to stop and cancel
                        the result of a member in case of fraud or
                        non-compliance with the contest rules and the right to
                        adjudicate complaints from team members.
                    </TextInfo>
                </TextWrapper>
                <p style={{ paddingBottom: 30 }} />
            </EventRuleContainer>
        </EventRuleWrapper>
    );
});
