import { useEffect } from 'react';
import { EventInfoContainer, LeftSide, RightSide } from './EventInfo.styles';
import './styles.css';
import EventBannerSection from './EventBannerSection';
import InfoAchieveSection from './InfoAchieveSection';
import InfoBxhSection from './InfoBxh';
import ContestRules from './ ContestRules';
import DonationsOrg from './DonationsOrg';
import { useRootStore } from '../../providers/RootStoreProvider';
import { useNavigate }  from 'react-router-dom';
import { IEvent, IGetEventDetailRequest } from '../../types/IEvent';
import { APIResponse } from '../../models/APIResponse';
import { API_STATUS } from '../../configs/APIStatus';
import { observer } from 'mobx-react-lite';

const EventInfo = observer(() => {
    const store = useRootStore();
    const { currentEvent,  getEventDetail } = store.eventInfoStore;
    const navigate = useNavigate();
    
    useEffect(() => {
        onGetEventDetail();
    },[]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    const onGetEventDetail = async () => {
        const params: IGetEventDetailRequest = {
          data: {
            evid: process.env.REACT_APP_UPRACE_DAY_ID!,
          },
        };
        const result: APIResponse<IEvent> = await getEventDetail(params);
        //check if events not exist then navigate to not found page
        //if (result.responseCode() === API_STATUS.EVENT.EVENT_NOT_EXIST) navigate('/not-found');
      };
    
    return (
        <EventInfoContainer className="event-bg">
            <LeftSide>
            {currentEvent ? <EventBannerSection eventInfo={currentEvent} /> : null}
                {currentEvent ?  <InfoAchieveSection eventInfo={currentEvent} /> : null}
                {currentEvent ? <InfoBxhSection eventInfo={currentEvent} /> : null}
            </LeftSide>
            <RightSide>
                {currentEvent ? <ContestRules data={currentEvent} /> : null}
                {currentEvent ? <DonationsOrg eventInfo={currentEvent} /> : null}
            </RightSide>
        </EventInfoContainer>
    );
});

export default EventInfo;
